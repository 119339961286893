<template>
  <Row>
    <Column md="2"></Column>
    <Column md="8">
      <div class="privat-order wrapper ff-flama">
        <Row>
          <Column md="12">
            <MarkedPlassenBanner
              @click="backToLanding()"/>
          </Column>
        </Row>
        <Row class="privat-order-description my-3 mx-3">
          <column
            md="12"
            class="description">
            <div class="privat-promo-desc">
              <p>Som privatannonsør får du annonsen din vist i papiravisen Fiskeribladet.</p>
            </div>
          </column>
        </Row>
        <Row class="privat-order-products my-3 mx-0">
          <Column md="12">
            <Row class="privat-product-column-wrapper">
              <Column
                v-for="product in productInfo"
                :key="product.id"
                md="4"
                class="privat-product-column">
                <div
                  v-text="product.name"
                  class="product-name fw-md"></div>
                <div class="product-desc">
                  <p
                    v-if="product.note !== ''"
                    class="fw-light st-italic my-0">{{ product.note }}</p>
                  <p class="fw-light my-0">{{ product.indents }}</p>
                  <p class="my-0"><span class="fw-md">Overskrift:</span> <span class="fw-light">{{ product.overskritt }}</span></p>
                  <p class="my-0"><span class="fw-md">Tekst:</span> <span class="fw-light">{{ product.tekst }}</span></p>
                  <p class="fw-bold">{{ product.price }}</p>
                </div>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row class="privat-contacts my-3 mx-3">
          <Column
            md="12"
            class="text-center">
            <div class="help-heading">Trenger du hjelp med din annonse, ta kontakt med oss!</div>
            <div class="contact-info d-inline-block">
              <div class="d-inline-block contact-email mx-5">
                <a href="mailto:annonse@fbfi.no">
                  <FontAwesomeIcon
                    :icon="emailIco"
                    size="lg"
                    class="emailIco pr-3"/>annonse@fbfi.no</a>
              </div>
              <div class="d-inline-block contact-phone mx-5">
                <a href="tel:+4755213300">
                  <FontAwesomeIcon
                    :icon="phoneIco"
                    size="lg"
                    class="phoneIco pr-3"/>55 21 33 00</a>
              </div>
            </div>
          </Column>
        </Row>
      </div>
    </Column>
    <Column md="2"></Column>
  </Row>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/pro-regular-svg-icons';
import { Page, MarkedPlassenBanner } from 'global-components';
import bestillAnnonse from 'global-configs/bestillAnnonse';

export default {
  name: 'order-privat',
  components: {
    MarkedPlassenBanner,
    FontAwesomeIcon
  },
  extends: Page,
  data() {
    return {
      productInfo: bestillAnnonse[this.$pubCtx.name].privat,
      emailIco: faEnvelope,
      phoneIco: faPhone
    };
  },
  methods: {
    backToLanding() {
      this.$router.push({ path: '/bestill-annonse/privat' });
    }
  }
};
</script>
